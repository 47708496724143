.footer-container {
  background-color: #44233b;
  .bottom-box {
    padding: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    div {
      display: flex;
      flex-direction: column;
      gap: 10px;
      h3 {
        color: #fd8e8f;
      }
      p {
        margin: 0;
      }
      a {
        display: flex;
        align-items: center;
        gap: 5px;
        text-decoration: none;
      }
      p,
      a {
        color: #cfb7b3;
      }
    }
  }
  .ad {
    border-top: 0.1px solid #e3e3e3;
    color: #cfb7b3;
    padding: 10px 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 5px;
    font-size: 0.8rem;
    align-items: center;
    a {
      margin: 10px;
      color: #fff;
    }
  }
}

@media (max-width: 600px) {
  .bottom-box {
    flex-direction: column;
    gap: 20px;
    img {
      width: 80px;
    }
  }
}
