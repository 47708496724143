@mixin rowGap($gap) {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: $gap;
}

.root-navbar {
  display: flex;
  justify-content: space-between;
  padding: 10px 1rem;
  align-items: center;
  background-color: #feefe1;
  flex-wrap: wrap;
  .title {
    color: #412236;
    font-size: 1.4rem;
  }
  .contact-container {
    display: flex;
    align-items: center;
    gap: 20px;
    .logout-btn {
      align-self: stretch;
      width: 100px;
      border-radius: 100px;
      border: none;
      background-color: #981e6e;
      color: #fff;
      cursor: pointer;
    }
  }
  .logo {
    width: 100px;
    height: 50px;
  }
  .contact {
    @include rowGap(10px);
    background-color: #f5e0ce;
    height: 40px;
    border-radius: 25px;
    padding: 5px 20px;
    font-weight: 600;
    cursor: pointer;
  }
}
.navbar {
  background-color: #412236;
  padding: 1rem;
  @include rowGap(20px);
  position: sticky;
  top: 0;
  justify-content: center;
  .link {
    text-decoration: none;
    color: #fd8e8f;
    font-size: 1.2rem;
    font-weight: 500;
  }
  .active {
    color: #fff;
  }
  .link:hover {
    color: #fff;
  }
}

@media (max-width: 700px) {
  .root-navbar {
    font-size: 0.8rem;
    .contact {
      font-size: 0.8rem;
      display: flex;
    }
    .title {
      display: none;
    }
  }
}
