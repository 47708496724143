.container {
  display: flex;
  height: 500px;
  align-items: center;
  justify-content: center;
  .loader {
    width: 20px;
    height: 20px;
    border: 4px solid #44233b49;
    border-radius: 50%;
    border-left-color: #a30f7c;
    animation: loader 0.8s linear infinite;
  }
}

@keyframes loader {
  from {
    rotate: 0deg;
  }
  to {
    rotate: 360deg;
  }
}
